// Do not edit.
import { ShaderStore } from "../../Engines/shaderStore";

const name = "vertexColorMixing";
const shader = `#if defined(VERTEXCOLOR) || defined(INSTANCESCOLOR)
vColor=vec4(1.0);#ifdef VERTEXCOLOR
vColor*=color;#endif
#ifdef INSTANCESCOLOR
vColor*=instanceColor;#endif
#endif
`;
// Sideeffect
ShaderStore.IncludesShadersStore[name] = shader;
/** @hidden */
export const vertexColorMixing = { name, shader };
